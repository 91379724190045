import { configureStore } from '@reduxjs/toolkit'
import authReducer from './auth/authSlice'

import reducers from '../reducers'

export const store = configureStore({
  reducer: {
    ...reducers,
    auth: authReducer,
  },
})

import React, { Suspense, lazy } from 'react'
// Router
import { Navigate, useRoutes, Outlet } from 'react-router-dom'
// Redux
import { useSelector } from 'react-redux'
// Layouts
import { LeftSidebar } from './layout-blueprints'
import { ClimbingBoxLoader } from 'react-spinners'

// ---------------------------------------------------------------------------------

const SuspenseLoading = () => (
  <div className='d-flex align-items-center flex-column vh-100 justify-content-center text-center py-3'>
    <div className='d-flex align-items-center flex-column px-4'>
      <ClimbingBoxLoader color='#3c44b1' loading />
    </div>
    <div className='text-muted font-size-xl text-center pt-3'>
      {/* Please wait while we load the live preview examples */}
      <span className='font-size-lg d-block text-dark'>
        {/* This live preview instance can be slower than a real production
        build! */}
        Cargando ....
      </span>
    </div>
  </div>
)

const Loadable = Component => props => {
  return (
    <Suspense fallback={<SuspenseLoading />}>
      <Component {...props} />
    </Suspense>
  )
}

// ---------------------------------------------------------------------------------

export default () =>
  useRoutes([
    {
      path: '/dashboard',
      element: (
        <ProtectedRoute>
          <LeftSidebar />
        </ProtectedRoute>
      ),
      children: [
        { path: 'customer/profile/:id', element: <CustomerProfile /> },

        // Fundamental routes
        { path: 'recollected', element: <Recollected /> },
        { path: 'recollected_range', element: <RecollectedByRange /> },
        { path: 'loans', element: <LoanList /> },
        { path: 'customers', element: <CustomerList /> },
        { path: 'customer_categories', element: <CustomerCategories /> },

        // Herramintas routes
        { path: 'pay_today', element: <PayToday /> },
        { path: 'pay_late', element: <PayLate /> },
        { path: 'pay_never', element: <PayNever /> },
        { path: 'pay_commitment', element: <PayCommitment /> },

        { path: 'money_collected', element: <MoneyCollected /> },
        { path: 'loan_constituted', element: <LoansConstituted /> },
        { path: 'loans_cancelled', element: <LoansCancelled /> },
        { path: 'expense_report', element: <ExpenseReport /> },

        { path: 'expenses', element: <Expenses /> },
        { path: 'expense_categories', element: <ExpenseCategories /> },

        { path: 'company_profile', element: <CompanyProfile /> },
        { path: 'employees', element: <Employees /> },

        // Admin routes
        { path: 'roles', element: <Roles /> },
        { path: 'permissions', element: <Permissions /> },

        // Tutoriales routes
        { path: 'frequent_questions', element: <FrequentQuestions /> },
        { path: 'user_manual', element: <UserManual /> },
      ],
    },
    {
      path: '/',
      element: <RedirectToDashboard />,
      children: [
        { path: '/', element: <Home /> },
        { path: 'login', element: <Login /> },
        { path: 'register', element: <RegisterOneLead /> },
      ],
    },
    { path: '*', element: <NotFound /> },
  ])

// ----------------------------------------------------------------------

const ProtectedRoute = ({ children }) => {
  const { employee } = useSelector(state => state.auth)

  if (!employee) {
    return <Navigate to='/' />
  }

  return children
}

// ----------------------------------------------------------------------

const RedirectToDashboard = () => {
  const { employee } = useSelector(state => state.auth)

  if (employee) {
    return <Navigate to='/dashboard/recollected' />
  }

  return <Outlet />
}

// ---------------------------------------------------------------------------------

// Marketing pages
const Home = Loadable(lazy(() => import('./pages/Home')))

// Auth pages
const Login = Loadable(lazy(() => import('./pages/Login')))
const RegisterOneLead = Loadable(lazy(() => import('./pages/RegisterOneLead')))

// Helper pages
const CustomerProfile = Loadable(lazy(() => import('./pages/CustomerProfile')))
const NotFound = Loadable(lazy(() => import('./pages/NotFound')))

// Fundamental pages
const Recollected = Loadable(lazy(() => import('./pages/Recollected')))
const RecollectedByRange = Loadable(lazy(() => import('./pages/RecollectedByRange')))
const LoanList = Loadable(lazy(() => import('./pages/LoanList')))
const CustomerList = Loadable(lazy(() => import('./pages/CustomerList')))
const CustomerCategories = Loadable(lazy(() => import('./pages/CustomerCategories')))

// Herramientas pages
const PayToday = Loadable(lazy(() => import('./pages/PayToday/index')))
const PayLate = Loadable(lazy(() => import('./pages/PayLate/index')))
const PayNever = Loadable(lazy(() => import('./pages/PayNever/index')))
const PayCommitment = Loadable(lazy(() => import('./pages/PayCommitment/index')))

const MoneyCollected = Loadable(lazy(() => import('./pages/Reports/MoneyCollected')))
const LoansConstituted = Loadable(lazy(() => import('./pages/Reports/LoansConstituted')))
const LoansCancelled = Loadable(lazy(() => import('./pages/Reports/LoansCancelled')))
const ExpenseReport = Loadable(lazy(() => import('./pages/Reports/ExpenseReport')))

const Expenses = Loadable(lazy(() => import('./pages/Expenses')))
const ExpenseCategories = Loadable(lazy(() => import('./pages/ExpenseCategories')))

const CompanyProfile = Loadable(lazy(() => import('./pages/CompanyProfile')))
const Employees = Loadable(lazy(() => import('./pages/Employees')))

// Admin pages
const Roles = Loadable(lazy(() => import('./pages/Roles')))
const Permissions = Loadable(lazy(() => import('./pages/Permissions')))

// Tutoriales pages
const FrequentQuestions = Loadable(lazy(() => import('./pages/Tutorials/FrequentQuestions')))
const UserManual = Loadable(lazy(() => import('./pages/Tutorials/UserManual')))

import React from 'react'

import clsx from 'clsx'
import { Link } from 'react-router-dom'

import { Nav, NavItem } from 'reactstrap'
import { NavLink as NavLinkStrap } from 'reactstrap'

import { connect } from 'react-redux'

const Footer = props => {
  const { footerShadow, footerBgTransparent } = props

  const date = new Date()
  const year = date.getFullYear()
  return (
    <>
      <div
        className={clsx('app-footer text-black-50', {
          'app-footer--shadow': footerShadow,
          'app-footer--opacity-bg': footerBgTransparent,
        })}
      >
        <div className='app-footer--first'>
          <Nav>
            <NavItem>
              <NavLinkStrap
                href='https://www.facebook.com/prestadinpuntocom'
                target='_blank'
                // tag={Link}
                // to="/contact"
                className='rounded-sm'
              >
                <b>Prestadin</b>
              </NavLinkStrap>
            </NavItem>
            {/* <NavItem>
              <NavLinkStrap
                tag={Link}
                // to="/DashboardStatistics"
                className="rounded-sm">
                victorimurillomora@gmail.com
              </NavLinkStrap>
            </NavItem> */}
            {/* <NavItem>
              <NavLinkStrap tag={Link} to="/Overview" className="rounded-sm">
                Overview
              </NavLinkStrap>
            </NavItem> */}
          </Nav>
        </div>
        <div className='app-footer--second'>
          <span>Sistema para Prestamistas</span> © {year} - diseñada con{' '}
          <span className='text-danger px-1'>❤</span> por PrestaDin Team
          {/* <a
            href="https://uifort.com"
            target="_blank"
            rel="noopener noreferrer"
            title="UiFort.com">
            Victori
          </a> */}
        </div>
      </div>
    </>
  )
}
const mapStateToProps = state => ({
  footerShadow: state.ThemeOptions.footerShadow,
  footerBgTransparent: state.ThemeOptions.footerBgTransparent,
})

export default connect(mapStateToProps)(Footer)

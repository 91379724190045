let URL

if (!process.env.NODE_ENV || process.env.NODE_ENV === 'development') {
  // dev code
  URL = 'http://localhost:3003'
  // URL = 'https://api.prestadin.com'
} else {
  // production code
  URL = 'https://api.prestadin.com'
}

export const HOST_API = URL

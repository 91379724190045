import React from 'react'
import { UncontrolledTooltip } from 'reactstrap'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Bell, Activity, Calendar } from 'react-feather'
import logo from '../../assets/images/prestadin/logo.png'

const SidebarFooter = () => {
  return (
    <>
      <div className='app-sidebar--footer'>
        <ul>
          <li>
            <span onClick={e => e.preventDefault()} className='btn btn-sm btn-transition-none px-2 mx-2'>
              <div
                className='border-0'
                style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center' }}
              >
                <img alt='Prestadin' src={logo} style={{ width: 30, paddingTop: 0, marginLeft: 5 }} />
                <span
                  style={{
                    marginRight: 0,
                    marginLeft: 5,
                    fontSize: 20,
                    color: 'white',
                  }}
                >
                  PrestaDin
                </span>
              </div>
            </span>
          </li>
          <li>
            <a
              href='https://www.facebook.com/prestadinpuntocom'
              target='_blank'
              className='btn btn-sm btn-transition-none px-2 mx-2'
              id='FacebookTooltip'
            >
              <FontAwesomeIcon style={{ fontSize: 27 }} icon={['fab', 'facebook-f']} />
            </a>
            <UncontrolledTooltip target='FacebookTooltip' container='.app-sidebar--footer'>
              Facebook
            </UncontrolledTooltip>
          </li>
          <li>
            <a
              href='https://www.instagram.com/prestadin/?hl=es-la'
              target='_blank'
              className='btn btn-sm btn-transition-none px-1 mx-2'
              id='InstagramTooltip'
            >
              <FontAwesomeIcon style={{ fontSize: 28 }} icon={['fab', 'instagram']} />
            </a>
            <UncontrolledTooltip target='InstagramTooltip' container='.app-sidebar--footer'>
              Instagram
            </UncontrolledTooltip>
          </li>
          <li>
            <a
              href='https://wa.me/50687202626?text=Hola, tengo una consulta sobre Prestadin.'
              target='_blank'
              className='btn btn-sm btn-transition-none px-1 mx-2'
              id='WhatsappTooltip'
            >
              <FontAwesomeIcon style={{ fontSize: 28 }} icon={['fab', 'whatsapp']} />
            </a>
            <UncontrolledTooltip target='WhatsappTooltip' container='.app-sidebar--footer'>
              WhatsApp
            </UncontrolledTooltip>
          </li>
        </ul>
      </div>
    </>
  )
}

export default SidebarFooter

import React, { useState, useEffect, useMemo, useCallback } from 'react'
import DataListInput from 'react-datalist-input'
import axios from 'axios'
import clsx from 'clsx'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { connect, useSelector } from 'react-redux'
import { setHeaderSearchHover } from '../../reducers/ThemeOptions'
import removeAccents from 'remove-accents'

// Router
import { useNavigate } from 'react-router-dom'

const HeaderSearch = props => {
  const { employee } = useSelector(state => state.auth)

  const navigate = useNavigate()
  const { headerSearchHover, setHeaderSearchHover } = props

  const [customers, setCustomers] = useState([])

  const [userId, setUserId] = useState()

  const [openMouseOver, setOpenMouseOver] = useState(true)

  // const toggleHeaderSearchHover = () => {
  //   setHeaderSearchHover(!headerSearchHover);
  // };

  const fetchCustomers = async () => {
    const url = `${process.env.REACT_APP_API}/user/list/customers/${employee.company._id}/${employee._id}`

    const { data } = await axios.get(url)
    setCustomers(data.data)
  }

  useEffect(() => {
    fetchCustomers()
  }, [])

  const onSelect = selectedItem => {
    setUserId(selectedItem.id)

    if (selectedItem.id) {
      navigate(`/dashboard/customer/profile/${selectedItem.label.split(' ').join('_')}`, {
        state: { userId: selectedItem.id },
      })
    }
  }

  const items = useMemo(
    () =>
      [...customers, { name: '', _id: '' }].map((item, index) => ({
        label: removeAccents(item.name),
        key: index,
        id: item._id,
      })),
    [[...customers, { name: '', _id: '' }]]
  )

  // const handleMouseUp = () => {
  //   fetchCustomers()
  //   setHeaderSearchHover(true)
  // }

  const handleMouseOver = () => {
    fetchCustomers()
    setOpenMouseOver(false)
  }

  const handleClose = () => {
    setHeaderSearchHover(false)
    setOpenMouseOver(true)
  }

  const changeTildes = vocal => {
    switch (vocal) {
      case 'Í':
        return 'I'

      default:
        break
    }
  }

  const match = (currentInput, item) => {
    let query = removeAccents(currentInput.toUpperCase())
    if (customers.length === item.key) return true
    return item.label.toUpperCase().includes(query)
  }

  return (
    <>
      <div className='header-search-wrapper'>
        <div
          className={clsx('search-wrapper', {
            'is-active': headerSearchHover,
          })}
        >
          <label className='icon-wrapper text-black' htmlFor='header-search-input'>
            <FontAwesomeIcon icon={['fas', 'search']} />
          </label>

          <div
            onMouseUp={() => setHeaderSearchHover(true)}
            onMouseOver={openMouseOver ? handleMouseOver : () => {}}
          >
            <DataListInput
              inputClassName='form-control'
              id='header-search-input'
              placeholder='Buscar Clientes...'
              items={items}
              onSelect={onSelect}
              onDropdownOpen={() => setHeaderSearchHover(true)}
              onDropdownClose={handleClose}
              clearInputOnSelect={true}
              suppressReselect={false}
              match={match}
            />
          </div>
        </div>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  headerSearchHover: state.ThemeOptions.headerSearchHover,
})

const mapDispatchToProps = dispatch => ({
  setHeaderSearchHover: enable => dispatch(setHeaderSearchHover(enable)),
})

export default connect(mapStateToProps, mapDispatchToProps)(HeaderSearch)

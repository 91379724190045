import { useEffect } from 'react'
// Redux
import { useSelector, useDispatch } from 'react-redux'
import { logout } from '../features/auth/authSlice'
import moment from 'moment'

export default () => {
  const dispatch = useDispatch()
  const { employee } = useSelector(state => state.auth)

  useEffect(() => {
    let intervalId

    if (employee) {
      // ---------------------------------------------------------

      // set lastReload only when enter the website
      if (employee?.iat) {
        var isoWeekDayLastLoggedIn = moment(new Date(employee.iat * 1000)).isoWeekday()
        const isoWeekDayNow = moment().isoWeekday()

        if (isoWeekDayLastLoggedIn !== isoWeekDayNow) {
          localStorage.setItem('lastReload', new Date())
        }
      }

      // ---------------------------------------------------------

      intervalId = setInterval(() => {
        // Refresh the token a minute early to avoid latency issues
        const expirationTime = employee.timeTokenExpires * 1000 - 5000
        const dateNow = Date.now()

        if (dateNow >= expirationTime) {
          dispatch(logout())
        }
        // else {
        //   const minutesToExpire = (expirationTime - dateNow) / 60000
        //   console.log(minutesToExpire)
        // }

        // ---------------------------------------------------------

        // reload website when change the day
        if (employee?.iat && !localStorage.getItem('lastReload')) {
          const isoWeekDayNow = moment().isoWeekday()

          if (isoWeekDayLastLoggedIn !== isoWeekDayNow) {
            localStorage.setItem('lastReload', new Date())
            location.reload()
          }
        }
      }, 5000)
    }

    return () => {
      clearInterval(intervalId)
    }
  }, [employee, dispatch])
}

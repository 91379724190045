import React, { useState } from 'react'
import clsx from 'clsx'
import { Collapse } from 'reactstrap'
import { connect } from 'react-redux'
import { setSidebarToggleMobile } from '../../reducers/ThemeOptions'
import { Users, ChevronRight, DollarSign, Award } from 'react-feather'
import { NavLink } from 'react-router-dom'
import { useSelector } from 'react-redux'

// ---------------------------------------------------------------------------------

const SidebarMenu = props => {
  const { employee } = useSelector(state => state.auth)
  const { setSidebarToggleMobile, sidebarUserbox } = props

  const toggleSidebarMobile = () => setSidebarToggleMobile(false)

  const [recollectedOpen, setRecollectedOpen] = useState(false)
  const toggleRecollected = event => {
    setRecollectedOpen(!recollectedOpen)
    event.preventDefault()
  }

  const [paganOpen, setPaganOpen] = useState(false)
  const togglePagan = event => {
    setPaganOpen(!paganOpen)
    event.preventDefault()
  }

  const [reportesOpen, setReportesOpen] = useState(false)
  const toggleReportes = event => {
    setReportesOpen(!reportesOpen)
    event.preventDefault()
  }

  const [empresaOpen, setEmpresaOpen] = useState(false)
  const toggleEmpresa = event => {
    setEmpresaOpen(!empresaOpen)
    event.preventDefault()
  }

  const [gastosOpen, setGastosOpen] = useState(false)
  const toggleGastos = event => {
    setGastosOpen(!gastosOpen)
    event.preventDefault()
  }

  const [customersOpen, setCustomersOpen] = useState(false)
  const toggleCustomers = event => {
    setCustomersOpen(!customersOpen)
    event.preventDefault()
  }

  const [rolesOpen, setRolesOpen] = useState(false)
  const toggleRoles = event => {
    setRolesOpen(!rolesOpen)
    event.preventDefault()
  }

  return (
    <>
      <>
        {/* {sidebarUserbox && <SidebarUserbox />} */}
        <div className='sidebar-navigation'>
          <div className='sidebar-header'>
            <span>Fundamental</span>
          </div>
          <ul>
            <li>
              <a href='#/' onClick={toggleRecollected} className={clsx({ active: recollectedOpen })}>
                <span className='sidebar-icon'>
                  <i className='fas fa-wallet'></i>
                </span>

                <span className='sidebar-item-label'>Recolectado</span>
                <span className='sidebar-icon-indicator'>
                  <ChevronRight />
                </span>
              </a>

              <Collapse isOpen={recollectedOpen}>
                <ul>
                  <li>
                    <NavLink
                      activeclassname='active'
                      onClick={toggleSidebarMobile}
                      className='nav-link-simple'
                      to='/dashboard/recollected'
                    >
                      Por Día
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeclassname='active'
                      onClick={toggleSidebarMobile}
                      className='nav-link-simple'
                      to='/dashboard/recollected_range'
                    >
                      Por Rango
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>

            {/* ------------------------------------------------------------------ */}

            {/* <li>
              <NavLink onClick={toggleSidebarMobile} className='nav-link-simple' to='/dashboard/recollected'>
                <span className='sidebar-icon'>
                  <i className='fas fa-wallet'></i>
                </span>
                <span className='sidebar-item-label'>Recolectado</span>
              </NavLink>
            </li> */}

            {/* ------------------------------------------------------------------ */}

            <li>
              <NavLink
                activeclassname='active'
                onClick={toggleSidebarMobile}
                className='nav-link-simple'
                to='/dashboard/loans'
              >
                <span className='sidebar-icon'>
                  <i style={{ fontSize: 22 }} className='fas fa-coins'></i>
                </span>
                <span className='sidebar-item-label'>Préstamos</span>
              </NavLink>
            </li>

            {/* ------------------------------------------------------------------ */}

            <li>
              <a href='#/' onClick={toggleCustomers} className={clsx({ active: customersOpen })}>
                <span className='sidebar-icon'>
                  <Users />
                </span>

                <span className='sidebar-item-label'>Clientes</span>
                <span className='sidebar-icon-indicator'>
                  <ChevronRight />
                </span>
              </a>

              <Collapse isOpen={customersOpen}>
                <ul>
                  <li>
                    <NavLink
                      activeclassname='active'
                      onClick={toggleSidebarMobile}
                      className='nav-link-simple'
                      to='/dashboard/customers'
                    >
                      Lista de Clientes
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeclassname='active'
                      onClick={toggleSidebarMobile}
                      className='nav-link-simple'
                      to='/dashboard/customer_categories'
                    >
                      Categorías
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>

            <div className='sidebar-header'>
              <span>Herramientas</span>
            </div>

            {/* ------------------------------------------------------------------ */}

            <li>
              <a href='#/' onClick={togglePagan} className={clsx({ active: paganOpen })}>
                <span className='sidebar-icon'>
                  <i style={{ fontSize: 26 }} className='fas fa-running'></i>
                </span>
                <span className='sidebar-item-label'>Pagan</span>
                <span className='sidebar-icon-indicator'>
                  <ChevronRight />
                </span>
              </a>
              <Collapse isOpen={paganOpen}>
                <ul>
                  <li>
                    <NavLink
                      activeclassname='active'
                      onClick={toggleSidebarMobile}
                      className='nav-link-simple'
                      to='/dashboard/pay_today'
                    >
                      Pagan Hoy
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} to='/dashboard/pay_late'>
                      Atrasados pero pagan
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} to='/dashboard/pay_never'>
                      Atrasados (nunca han pagado)
                    </NavLink>
                  </li>
                  <li>
                    <NavLink onClick={toggleSidebarMobile} to='/dashboard/pay_commitment'>
                      Compromisos de Pago
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>

            {/* ------------------------------------------------------------------ */}

            <li>
              <a href='#/' onClick={toggleGastos} className={clsx({ active: gastosOpen })}>
                <span className='sidebar-icon'>
                  <DollarSign />
                </span>

                <span className='sidebar-item-label'>Gastos</span>
                <span className='sidebar-icon-indicator'>
                  <ChevronRight />
                </span>
              </a>
              <Collapse isOpen={gastosOpen}>
                <ul>
                  <li>
                    <NavLink
                      activeclassname='active'
                      onClick={toggleSidebarMobile}
                      className='nav-link-simple'
                      to='/dashboard/expenses'
                    >
                      Gastos
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeclassname='active'
                      onClick={toggleSidebarMobile}
                      className='nav-link-simple'
                      to='/dashboard/expense_categories'
                    >
                      Categorías
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>

            {/* ------------------------------------------------------------------ */}

            <li>
              <a href='#/' onClick={toggleReportes} className={clsx({ active: reportesOpen })}>
                <span className='sidebar-icon'>
                  <i style={{ fontSize: 24 }} className='fas fa-chart-line'></i>
                </span>
                <span className='sidebar-item-label'>Reportes</span>
                <span className='sidebar-icon-indicator'>
                  <ChevronRight />
                </span>
              </a>
              <Collapse isOpen={reportesOpen}>
                <ul>
                  <li>
                    <NavLink
                      activeclassname='active'
                      onClick={toggleSidebarMobile}
                      className='nav-link-simple'
                      to='/dashboard/money_collected'
                    >
                      Dinero Cobrado
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeclassname='active'
                      onClick={toggleSidebarMobile}
                      className='nav-link-simple'
                      to='/dashboard/loan_constituted'
                    >
                      Préstamos Constituidos
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeclassname='active'
                      onClick={toggleSidebarMobile}
                      className='nav-link-simple'
                      to='/dashboard/loans_cancelled'
                    >
                      Préstamos Cancelados
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeclassname='active'
                      onClick={toggleSidebarMobile}
                      className='nav-link-simple'
                      to='/dashboard/expense_report'
                    >
                      Gastos por Categorías
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>

            {/* ------------------------------------------------------------------ */}

            <li>
              <a href='#/' onClick={toggleEmpresa} className={clsx({ active: empresaOpen })}>
                <span className='sidebar-icon'>
                  <i style={{ fontSize: 20 }} className='fas fa-business-time'></i>
                </span>
                <span className='sidebar-item-label'>Empresa</span>
                <span className='sidebar-icon-indicator'>
                  <ChevronRight />
                </span>
              </a>
              <Collapse isOpen={empresaOpen}>
                <ul>
                  <li>
                    <NavLink
                      activeclassname='active'
                      onClick={toggleSidebarMobile}
                      className='nav-link-simple'
                      to='/dashboard/company_profile'
                    >
                      Perfil de Empresa
                    </NavLink>
                  </li>
                  <li>
                    <NavLink
                      activeclassname='active'
                      onClick={toggleSidebarMobile}
                      className='nav-link-simple'
                      to='/dashboard/employees'
                    >
                      Empleados
                    </NavLink>
                  </li>
                </ul>
              </Collapse>
            </li>

            {/* ------------------------------------------------------------------ */}
            {/* only CREDIDAY can see */}
            {employee.company._id === '5d66113425d973732dcd2d73' && (
              <div className='sidebar-header'>
                <span>Administración</span>
              </div>
            )}

            {/* only CREDIDAY can see */}
            {employee.company._id === '5d66113425d973732dcd2d73' && (
              <li>
                <a href='#/' onClick={toggleRoles} className={clsx({ active: rolesOpen })}>
                  <span className='sidebar-icon'>
                    <Award />
                    {/* <i style={{ fontSize: 20 }} className='fas fa-business-time'></i> */}
                  </span>
                  <span className='sidebar-item-label'>Roles</span>
                  <span className='sidebar-icon-indicator'>
                    <ChevronRight />
                  </span>
                </a>
                <Collapse isOpen={rolesOpen}>
                  <ul>
                    <li>
                      <NavLink
                        activeclassname='active'
                        onClick={toggleSidebarMobile}
                        className='nav-link-simple'
                        to='/dashboard/roles'
                      >
                        Lista de Roles
                      </NavLink>
                    </li>
                    <li>
                      <NavLink
                        activeclassname='active'
                        onClick={toggleSidebarMobile}
                        className='nav-link-simple'
                        to='/dashboard/permissions'
                      >
                        Permisos
                      </NavLink>
                    </li>
                  </ul>
                </Collapse>
              </li>
            )}

            <div className='sidebar-menu-box'>
              <div className='sidebar-header'>
                <span>Tutoriales</span>
              </div>
              <ul>
                <li>
                  <NavLink onClick={toggleSidebarMobile} className='nav-link-simple' to='/dashboard/frequent_questions'>
                    <span className='sidebar-icon'>
                      <i style={{ fontSize: 23 }} className='far fa-question-circle'></i>
                    </span>
                    <span className='font-weight-normal'>Preguntas Frecuentes</span>
                  </NavLink>
                </li>
                <li>
                  <NavLink onClick={toggleSidebarMobile} className='nav-link-simple' to='/dashboard/user_manual'>
                    <span className='sidebar-icon'>
                      <i style={{ fontSize: 23 }} className='fas fa-book'></i>
                    </span>
                    <span className='font-weight-normal'>Manual de Uso</span>
                  </NavLink>
                </li>
              </ul>
            </div>
          </ul>
        </div>
      </>
    </>
  )
}

const mapStateToProps = state => ({
  sidebarUserbox: state.ThemeOptions.sidebarUserbox,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
})

const mapDispatchToProps = dispatch => ({
  setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SidebarMenu)

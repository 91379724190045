import { createSlice, createAsyncThunk } from '@reduxjs/toolkit'
import authService from './authService'

// Get employee from localstorage
let employee = JSON.parse(localStorage.getItem('employee'))

// When open the website check TOKEN EXPIRATION
if (employee) {
  const expirationTime = employee.timeTokenExpires * 1000 - 5000

  if (Date.now() >= expirationTime) {
    employee = null
    // localStorage.removeItem('employee')
    localStorage.clear()
  }
}

const initialState = {
  employee: employee || null,
  isError: false,
  isSuccess: false,
  isLoading: false,
  message: '',
}

export const login = createAsyncThunk('auth/login', async (employee, thunkAPI) => {
  try {
    return await authService.login(employee)
  } catch (error) {
    let message =
      (error.response && error.response.data && error.response.data.message) ||
      error.message ||
      error.toString()

    if (message === 'Network Error') message = 'Contactar a soporte'

    return thunkAPI.rejectWithValue(message)
  }
})

export const logout = createAsyncThunk('auth/logout', async () => {
  await authService.logout()
})

export const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    reset: state => {
      state.isLoading = false
      state.isError = false
      state.isSuccess = false
      state.message = ''
    },
    updateCompanyName: (state, { payload }) => {
      let employee = JSON.parse(localStorage.getItem('employee'))
      employee.company.name = payload
      state.employee = employee
      localStorage.setItem('employee', JSON.stringify(employee))
    },
  },
  extraReducers: builder => {
    builder
      .addCase(logout.fulfilled, state => {
        state.employee = null
        location.replace('/')
      })
      .addCase(login.pending, state => {
        state.isLoading = true
      })
      .addCase(login.fulfilled, (state, action) => {
        state.isLoading = false
        state.isSuccess = true
        state.employee = action.payload
        location.replace('/dashboard/recollected')
      })
      .addCase(login.rejected, (state, action) => {
        state.isLoading = false
        state.isError = true
        state.message = action.payload
        state.employee = null
      })
  },
})

export const { reset, updateCompanyName } = authSlice.actions
export default authSlice.reducer

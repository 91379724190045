import React from 'react'
import { Row, Col, Container } from 'reactstrap'
import illustration1 from './assets/images/illustrations/pack1/security.svg'
import particles2 from './assets/images/hero-bg/particles-2.svg'

// redux
import { useSelector } from 'react-redux'

// hooks
import useWidth from './hooks/useWidth'

// router
import { useLocation } from 'react-router-dom'

export default ({ children }) => {
  const { pathname } = useLocation()
  const { width } = useWidth()
  const { employee } = useSelector(state => state.auth)

  const collectorForbiddenPaths = [
    'money_collected',
    'loan_constituted',
    'loans_cancelled',
    'expense_report',
    'company_profile',
    'employees',
  ]

  const secretaryForbiddenPaths = [
    'money_collected',
    'loan_constituted',
    'loans_cancelled',
    'expense_report',
    'company_profile',
    'employees',
  ]

  if (employee.role === 'Collector') {
    const pathNow = pathname.split('/').pop()

    if (!collectorForbiddenPaths.some(path => path === pathNow)) {
      return children
    }
  }

  if (employee.role === 'Secretary') {
    const pathNow = pathname.split('/').pop()

    if (!secretaryForbiddenPaths.some(path => path === pathNow)) {
      return children
    }
  }

  if (employee.role === 'Admin') return children

  return (
    <>
      <div className='app-wrapper bg-second'>
        <div className='w-100'>
          <div className='app-content p-0'>
            <div className='app-content--inner d-flex align-items-center'>
              <div className='flex-grow-1 w-100 d-flex align-items-center' style={{ minHeight: '60vh' }}>
                <div
                  className='bg-composed-wrapper--image bg-composed-filter-rm opacity-2 bg-repeat'
                  style={{ backgroundImage: 'url(' + particles2 + ')' }}
                />
                <div className='bg-composed-wrapper--content py-0'>
                  <Container>
                    <Row>
                      <Col md='2' />
                      <Col sm='12' md='8' className=''>
                        <div className='text-center text-white'>
                          <h3 className={`display-${width > 500 ? '3' : '4'} mb-1 px-0 font-weight-bold`}>
                            Permiso Denegado
                          </h3>
                        </div>

                        <img alt='...' className='w-100 mx-auto d-block img-fluid' src={illustration1} />
                      </Col>
                      <Col md='2' />
                    </Row>
                  </Container>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}

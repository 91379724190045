import axios from 'axios'
import jwtDecode from 'jwt-decode'
import { HOST_API } from '../../config'
import { isMobile, osName } from 'react-device-detect'
import { mobileVendor, mobileModel } from 'mobile-device-detect'
import detectBrowser from './detectBrowser'

// Logout employee
// const logout = async () => localStorage.removeItem('employee')
const logout = async () => {
  localStorage.clear()
}

// Login employee
const login = async ({ username, password }) => {
  const browser = detectBrowser()

  let country = ''

  // try {
  //   var res = await axios.get('https://geolocation-db.com/json/')
  //   country = res.data.country_name
  // } catch (error) {
  //   console.log(res)
  // }

  const json = {
    country,
    operatingSystem: osName,
    username: username.trim(),
    password: password.trim(),
    website: 'prestadin.com',
    device: isMobile ? 'Mobile' : 'Desktop',
    browser,
    modelDevice: `${mobileVendor} ${mobileModel}`,
  }

  const response = await axios.post(`${HOST_API}/employee/login`, json)
  let data

  if (response.data) {
    const { token } = response.data
    const { exp, iat } = jwtDecode(token)

    data = { ...response.data, timeTokenExpires: exp, iat }

    localStorage.setItem('employee', JSON.stringify(data))
  }

  return data
}

const authService = { logout, login }
export default authService

import React from 'react'

import clsx from 'clsx'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { UncontrolledTooltip } from 'reactstrap'

import { connect } from 'react-redux'
import { NavLink } from 'react-router-dom'

import { setSidebarToggle, setSidebarToggleMobile } from '../../reducers/ThemeOptions'

import projectLogo from '../../assets/images/upload.svg'
import { useSelector } from 'react-redux'

const SidebarHeader = props => {
  const { employee } = useSelector(state => state.auth)

  const toggleSidebarMobile = () => {
    setSidebarToggleMobile(!sidebarToggleMobile)
  }
  const toggleSidebar = () => {
    setSidebarToggle(!sidebarToggle)
  }
  const {
    sidebarToggleMobile,
    setSidebarToggleMobile,

    sidebarToggle,
    setSidebarToggle,
  } = props

  const [image, setImage] = React.useState(
    `https://do-spaces-prestadin.nyc3.digitaloceanspaces.com/companies/logo_${employee.company._id}.jpg`
  )

  return (
    <>
      <div className='app-sidebar--header' style={{ padding: '0 1.3rem' }}>
        <div className='app-sidebar-logo'>
          <NavLink
            to='/dashboard/company_profile'
            title='Bamburgh React Admin Dashboard with Reactstrap PRO'
            className='app-sidebar-logo'
            onClick={toggleSidebarMobile}
          >
            <div className='app-sidebar-logo--icon' style={{ height: 55, width: 55 }}>
              <img
                alt=''
                style={{
                  height: 55,
                  width: 55,
                  animationDuration: '0s',
                  borderRadius: 5,
                }}
                src={image}
                onError={() => setImage(projectLogo)}
              />
            </div>
          </NavLink>
          <div className='app-sidebar-logo--text' style={{ width: '100%' }}>
            <b style={{ textTransform: 'uppercase' }}>{employee.company.name || 'PrestaDin'}</b>
          </div>
        </div>
        <button
          onClick={toggleSidebar}
          className='btn btn-sm collapse-sidebar-btn'
          id='CollapseSidebarTooltip'
        >
          <FontAwesomeIcon icon={['far', 'dot-circle']} size='lg' />
        </button>
        <UncontrolledTooltip target='CollapseSidebarTooltip' container='.app-sidebar' placement='right'>
          Contraer barra lateral
        </UncontrolledTooltip>
        <button
          className={clsx('navbar-toggler hamburger hamburger--elastic toggle-mobile-sidebar-btn', {
            'is-active': sidebarToggleMobile,
          })}
          onClick={toggleSidebarMobile}
        >
          <span className='hamburger-box'>
            <span className='hamburger-inner' />
          </span>
        </button>
        <button onClick={toggleSidebar} className='expand-sidebar-btn btn btn-sm' id='ExpandSidebarTooltip'>
          <FontAwesomeIcon icon={['fas', 'arrows-alt-h']} />
        </button>
        <UncontrolledTooltip target='ExpandSidebarTooltip' container='.app-sidebar' placement='right'>
          Expandir barra lateral
        </UncontrolledTooltip>
      </div>
    </>
  )
}

const mapStateToProps = state => ({
  sidebarToggle: state.ThemeOptions.sidebarToggle,
  sidebarToggleMobile: state.ThemeOptions.sidebarToggleMobile,
})

const mapDispatchToProps = dispatch => ({
  setSidebarToggle: enable => dispatch(setSidebarToggle(enable)),
  setSidebarToggleMobile: enable => dispatch(setSidebarToggleMobile(enable)),
})

export default connect(mapStateToProps, mapDispatchToProps)(SidebarHeader)
